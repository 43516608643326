import { DefaultProps } from '../interface';
import json from './car-models';
import Card from 'react-bootstrap/Card';
import './car-models.scss';
import { FunctionComponent } from 'react';

const CarStandard: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const data = json[prefix];
  return (
    <div className="car-models">
      <h2 className="mb-2">{data.title}</h2>
      <div className="car-list">
        {data.list.map((item: any) => {
          return (
            <Card className="card--car-models mb-2" key={item.title}>
              <Card.Body className="d-flex flex-column flex-lg-row p-3">
                <Card.Title className="car-title">
                  <img src={item.src} alt={item.title} className="m-0 mb-2" />
                  <strong>{item.title}</strong>
                </Card.Title>
                <Card.Text className="car-description mb-0">{item.description}</Card.Text>
              </Card.Body>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default CarStandard;
