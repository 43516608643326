export default {
  th: {
    title: 'ประเภทรถยนต์',
    list: [
      {
        title: 'รถอีโคคาร์',
        description:
          'อีโคคาร์ย่อมาจากคำว่า Ecology Car หรือรถยนต์รักษาสิ่งแวดล้อม ไม่ใช่รถยนต์ประหยัดพลังงานหรือ Economy Car ซึ่งแม้จะดูเหมือนว่ามีความคล้ายคลึงกันแต่ก็มีความแตกต่างกัน ในแง่ของความหมาย ซึ่งแนวคิดของรถอีโคคาร์ในบ้านเรานั้น ต้องปฏิบัติตามกฏที่สำคัญหลายข้อ จึงจะผ่านการรับรองว่า รถคันดังกล่าวเป็นอีโคคาร์',
        src: '/assets/pages/help/cars/eco.png'
      },
      {
        title: 'รถขนาดเล็ก',
        description:
          'รถยนต์รุ่นที่ถูกจัดเป็นรถยนต์นั่งขนาดเล็ก หรือ Compact Car รถมีขนาดที่ไม่เล็กเกินไปสำหรับการเป็นรถครอบครัว แต่ไม่ใหญ่เทอะทะเกินไปสำหรับการขับขี่ในเมืองหรือการขับขี่ในฐานะรถส่วนบุคคล ด้วยขนาดของรถยนต์และเครื่องยนต์ที่เหมาะสมและลงตัว สามารถใช้รถยนต์ประเภทนี้ได้ในหลายลักษณะ',
        src: '/assets/pages/help/cars/small.png'
      },
      {
        title: 'รถขนาดกลาง',
        description:
          'รถยนต์นั่งขนาดกลาง มีขนาดภายในใหญ่พอที่จะรองรับผู้ใหญ่ 5 คน ได้โดยไม่เบียดเสียด มีเครื่องยนต์ที่สมรรถนะสูงขึ้น เพื่อรองรับน้ำหนักตัวรถที่มากขึ้น สามารถใช้เป็นรถสำหรับครอบครัวได้ดี',
        src: '/assets/pages/help/cars/medium.png'
      },
      {
        title: 'รถขนาดใหญ่',
        description:
          'รถยนต์นั่งขนาดใหญ่ หรือ Full-size car เป็นรถที่มีขนาดใหญ่ที่สุดในบรรดารถเก๋งทั้งหมด (ร่วมกับ Full-size luxury car) มีตัวถังภายในกว้างขวาง ตัวรถมีความยาว 4.9 เมตรขึ้นไป',
        src: '/assets/pages/help/cars/large.png'
      },
      {
        title: 'เอสยูวี',
        description:
          'รถยนต์อเนกประสงค์สมรรถนะสูง หรือ SUV (ย่อมาจาก Sport Utility Vehicle) มีพื้นที่ใช้สอยยืดหยุ่น และมักใช้เครื่องยนต์เบนซิน SUV จะมีสมรรถนะสูง สามารถใช้ไต่เขาชันและวิ่งทางวิบากได้ดี แต่การพื้นที่ใช้สอยอาจไม่ยืดหยุ่น(อเนกประสงค์)มากเท่ารถกระบะ แต่ก็ถือว่าอเนกประสงค์มากกว่ารถเก๋ง',
        src: '/assets/pages/help/cars/suv.png'
      },
      {
        title: 'รถตู้',
        description:
          'รถตู้ คือรถขนาดใหญ่มีพื้นที่ใช้สอยมาก รองรับผู้ใหญ่ 8 - 13 คน เป็นรถที่เหมาะกับการใช้งานเพื่อการเดินทางเป็นกลุ่มหรือหมู่คณะ รถตู้มีเครื่องยนต์ที่มีสมรรถนะสูง เพื่อรองรับน้ำหนักผู้โดยสารจำนวนมาก สามารถใช้เพื่อเดินทางข้ามจังหวัดหรือใช้เพื่อขึ้นเขาที่ไม่สูงชันมากนัก',
        src: '/assets/pages/help/cars/van.png'
      },
      {
        title: 'รถกระบะ',
        description:
          'รถกระบะ เป็นรถที่มีพื้นที่ว่างด้านท้ายรถจำนวนมาก และห้องโดยสารมีพื้นที่น้อย มักใช้ในธุรกิจขนาดเล็กและกลาง',
        src: '/assets/pages/help/cars/truck.png'
      },
      {
        title: 'รถพร้อมคนขับ',
        description:
          'รถยนต์ประเภทนี้ สามารถเลือกได้หลายประเภท ซึ่งจะเป็นรถที่มาพร้อมกับคนขับ เหมาะกับผู้ที่ไม่สามารถขับรถเองได้ หรือไม่สะดวกขับรถเอง',
        src: '/assets/pages/help/cars/with_driver.png'
      },
      {
        title: 'Luxury',
        description:
          'รถยนต์นั่งประเภทหรูหรา (Luxury) มี 3 ประเภทคือ ระดับต้น ขนาดกลาง และขนาดใหญ่ มักจะมีการตกแต่งภายในและอุปกรณ์ต่างๆ ที่หรูหรา อีกทั้งยังมีภาพลักษณ์ในการใช้เครื่องยนต์ขนาดใหญ่ที่มีกำลังและสมรรถนะสูง',
        src: '/assets/pages/help/cars/luxury.png'
      }
    ]
  },
  en: {
    title: 'Car Types:',
    list: [
      {
        title: 'Economy Car',
        description: (
          <>
            An economy car, also known as an <b>{`"Ecology Car"`}</b>, is designed with environmental sustainability in
            mind. It should not be confused with an <b>{`"Economy Car"`}</b> or a fuel-efficient car, as there are
            distinct differences. The concept of economy cars in our context adheres to several important regulations to
            earn the certification of being an <b>{`"Ecology Car."`}</b>
          </>
        ),
        src: '/assets/pages/help/cars/eco.png'
      },
      {
        title: 'Small Car',
        description: (
          <>
            A compact car is a small-sized passenger car, often referred to as a <b>{`"Compact Car."`}</b> It has a size
            suitable for families without being overly large, making it convenient for city driving and personal use.
            With its appropriate size and well-matched engine, this car type is versatile and can adapt to various
            situations.
          </>
        ),
        src: '/assets/pages/help/cars/small.png'
      },
      {
        title: 'Mid-Size Car',
        description:
          'A mid-size sedan offers ample interior space to comfortably accommodate up to five adults. It features a higher engine capacity to support the increased weight of the car, making it a suitable family vehicle.',
        src: '/assets/pages/help/cars/medium.png'
      },
      {
        title: 'Full-Size Car',
        description: (
          <>
            A full-size sedan, or <b>{`"Full-Size Car"`}</b>, is the largest type of sedan (including full-size luxury
            cars). It boasts a spacious interior and has a length of 4.9 meters or more.
          </>
        ),

        src: '/assets/pages/help/cars/large.png'
      },
      {
        title: 'SUV',
        description:
          'Sport Utility Vehicle (SUV) is a high-performance vehicle known for its flexibility and spaciousness. Most SUVs use gasoline engines and offer elevated ground clearance, making them suitable for off-road and hill climbing. While the interior space may not be as flexible as a minivan, SUVs are considered more versatile than sedans.',
        src: '/assets/pages/help/cars/suv.png'
      },
      {
        title: 'Minivan',
        description:
          'A minivan is a large-sized vehicle with ample interior space, accommodating 8 to 13 adults. It is ideal for group or family travel. Minivans have powerful engines to support a significant number of passengers. They can be used for interprovincial travel or for ascending moderately steep hills.',
        src: '/assets/pages/help/cars/van.png'
      },
      {
        title: 'Pickup Truck',
        description:
          'A pickup truck has a large open space at the rear and limited passenger cabin space. It is often used in small to medium-sized businesses.',
        src: '/assets/pages/help/cars/truck.png'
      },
      {
        title: 'Chauffeur Service',
        description:
          'This type of car offers various options and comes with a driver. It is suitable for individuals who cannot drive themselves or prefer not to.',
        src: '/assets/pages/help/cars/with_driver.png'
      },
      {
        title: 'Luxury',
        description:
          'Luxury cars come in three categories: entry-level, mid-size, and full-size. They are known for their luxurious interior decorations and high-end equipment. Additionally, they often feature larger, high-performance engines with advanced capabilities.',
        src: '/assets/pages/help/cars/luxury.png'
      }
    ]
  }
};
